import React from 'react';
import heroImage from './img/hero-img.svg';
import './hero.css';

export const Hero = () => {
    return (
        <section id="hero">

            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className=" col-lg-6 py-5 py-lg-0 order-2 order-lg-1" data-aos=" fade-right">
                        <h1>Your new digital experience with <span style={{color: 'white'}}>African Proficient IT Warehouse</span></h1>
                        <a href="#about" className=" btn-get-started scrollto">Get Started</a>
                    </div>
                    <div className=" col-lg-6 order-1 order-lg-2 hero-img" data-aos=" fade-left">
                        <img src={heroImage} className=" img-fluid" alt=""/>
                    </div>
                </div>
            </div>

        </section>
    );
}
