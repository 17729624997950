import React from 'react';
import logo from './img/logo-white.png';
import './footer.css';

export const Footer = () => {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container" data-aos="fade-up">
                    <div className="row  justify-content-center">
                        <div className="col-lg-6">
                            <img src={logo} className={'img-fluid'}/>
                            <p>Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi fuga maxime
                                saepe commodi placeat.</p>
                        </div>
                    </div>
                    <div className="social-links">
                        <a href="#" className="twitter"><i className="bx bxl-twitter"/></a>
                        <a href="#" className="facebook"><i className="bx bxl-facebook"/></a>
                        <a href="#" className="instagram"><i className="bx bxl-instagram"/></a>
                        <a href="#" className="google-plus"><i className="bx bxl-skype"/></a>
                        <a href="#" className="linkedin"><i className="bx bxl-linkedin"/></a>
                    </div>
                </div>
            </div>
            <div className="container footer-bottom clearfix">
                <div className="copyright">
                    &copy; Copyright <strong><span>African Proficient IT Warehouse</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Designed by <a href="https://bootstrapmade.com/">Yelah</a>
                </div>
            </div>
        </footer>
    );
}
