import clientImage1 from './client-1.png';
import clientImage2 from './client-2.png';
import clientImage3 from './client-3.png';
import clientImage4 from './client-4.png';
import clientImage5 from './client-5.png';
import clientImage6 from './client-6.png';
import clientImage7 from './client-7.png';
import clientImage8 from './client-8.png';

export const clientImages = [clientImage1, clientImage2, clientImage3, clientImage4,
    clientImage5, clientImage6, clientImage7, clientImage8];
