import React from 'react';
import './contact.css';

export const Contact = () => {
    return (
        <section id="contact" className="contact section-bg">
            <div className="container">

                <div className="section-title">
                    <h2 data-aos="fade-in">Contact</h2>
                    <p data-aos="fade-in">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid
                        fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui
                        impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="info-box" data-aos="fade-up">
                            <i className="bx bx-map"/>
                            <h3>Our Address</h3>
                            <p>A108 Adam Street, New York, NY 535022</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="info-box mt-4" data-aos="fade-up" data-aos-delay="100">
                            <i className="bx bx-envelope"/>
                            <h3>Email Us</h3>
                            <p>info@africanprofieientitwarehouse.co.za<br/>hello@africanprofieientitwarehouse.co.za</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="info-box mt-4" data-aos="fade-up" data-aos-delay="100">
                            <i className="bx bx-phone-call"/>
                            <h3>Call Us</h3>
                            <p>+27 123 456 7895<br/>+27 133 456 7895</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
