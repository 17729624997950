import React from 'react';
import logo from './img/logo-white.png';
import './header.css';

export const Header = () => {
    return (
        <header id="header" className={'fixed-top'}>
            <div className="container d-flex align-items-center">
                <div className="logo mr-auto">
                    <img src={logo} alt="" className="img-fluid"/>
                </div>

                <nav className="nav-menu d-none d-lg-block">
                    <ul>
                        <li className="active"><a href="index.html">Home</a></li>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#features">Services</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                        <li className="get-started"><a href="#about">Get Started</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
