import React from 'react';
import './about.css';

export const About = () => {
    return (
        <section id="about" className="about section-bg">
            <div className="container">

                <div className="row">
                    <div
                        className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"/>
                    <div className="col-xl-7 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                        <div className="content d-flex flex-column justify-content-center">
                            <h3 data-aos="fade-in" data-aos-delay="100">Voluptatem dignissimos provident quasi</h3>
                            <p data-aos="fade-in">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                            </p>
                            <div className="row">
                                <div className="col-md-6 icon-box" data-aos="fade-up">
                                    <i className="bx bx-receipt"/>
                                    <h4><a href="#">Corporis voluptates sit</a></h4>
                                    <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut
                                        aliquip</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bx bx-cube-alt"/>
                                    <h4><a href="#">Ullamco laboris nisi</a></h4>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bx bx-images"/>
                                    <h4><a href="#">Labore consequatur</a></h4>
                                    <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis
                                        facere</p>
                                </div>
                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bx bx-shield"/>
                                    <h4><a href="#">Beatae veritatis</a></h4>
                                    <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}
