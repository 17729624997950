import React from 'react';
import './clients.css';
import {clientImages} from './img';

export const Clients = () => {
    return (
        <section id="clients" className="clients section-bg">
            <div className="container">
                <div className="row no-gutters clients-wrap clearfix wow fadeInUp">
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="client-logo">
                            <img src={clientImages[0]} className="img-fluid" alt=""
                                 data-aos="flip-right"/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="client-logo">
                            <img src={clientImages[1]} className="img-fluid" alt=""
                                 data-aos="flip-right" data-aos-delay="100"/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="client-logo">
                            <img src={clientImages[2]} className="img-fluid" alt=""
                                 data-aos="flip-right" data-aos-delay="200"/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="client-logo">
                            <img src={clientImages[3]} className="img-fluid" alt=""
                                 data-aos="flip-right" data-aos-delay="300"/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="client-logo">
                            <img src={clientImages[4]} className="img-fluid" alt=""
                                 data-aos="flip-right" data-aos-delay="400"/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="client-logo">
                            <img src={clientImages[5]} className="img-fluid" alt=""
                                 data-aos="flip-right" data-aos-delay="500"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
